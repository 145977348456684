import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router.js';
import store from "@/store";
import Cookies from 'js-cookie';

import Web3 from 'web3';

// const Web3 = require('web3');
// Create a Web3 instance
const web3 = new Web3(process.env.VUE_APP_RPC);
// Check if connected
web3.eth.net.isListening()
 .then(() => console.log('Connected to Linea Testnet'))
 .catch(error => console.error('Linea Testnet connection error:', error));

const menuItems = [
  { icon: '/static/ico/dj.svg', goto: '/home', text: 'Discover' },
  { icon: '/static/ico/key-fill.svg', name_active: 'DashKeys', goto: '/keys', text: 'Keys' },
  { icon: '/static/ico/agents.svg', name_active: '', goto: '/agents', text: 'My Agents' },
  // { icon: '/static/ico/dj.svg', goto: '/feed', text: 'Feed β' },

  { icon: '/static/ico/drop.svg', name_active: 'DashDrop', goto: '/drop', text: 'Drop' },
  { icon: '/static/ico/user.svg', name_active: '', goto: '/profile', text: 'Profile' },
  //   { icon: '/static/ico/page.svg', name_active: '', goto: '/profile/posts', text: 'Posts' },
  { icon: '/static/ico/keys.svg', name_active: '', goto: '/profile/holdings', text: 'Holdings' },
  { icon: '/static/ico/mail.svg', name_active: 'DashChat', goto: '/chat', text: 'Messages' },
  // { icon: '/static/ico/folder.svg', name_active: '', goto: '/profile/holders', text: 'Holders' },
  { icon: '/static/ico/in.svg', name_active: 'DashRefs', goto: '/refs', text: 'Invite' },
  { icon: '/static/ico/set.svg', name_active: '', goto: '/profile/settings', text: 'Settings' },
];

const modelsList = {
        1: {
            platform: 1,
            name: "dall-e-3",
            // trainedUntil: "",
            // contextWindow: ,
            normalName: "DALL-E 3",
            shortDescription: "Specializes in generating diverse, creative images from text descriptions.",
            longDescription: "DALL-E 3 excels at converting textual descriptions into vivid, diverse images, showcasing remarkable artistic capabilities. It's an advanced AI model that understands and interprets a wide range of descriptive languages to produce visually stunning and relevant artwork. Ideal for creative applications, it offers enhanced flexibility and creativity for users seeking to generate unique visual content from textual inputs."
        },
        2: { // Currently points to gpt-4-0613.
            platform: 1,
            name: "gpt-4",
            contextWindow: 8192,
            trainedUntil: "Up to Sep 2021",
            normalName: "GPT-4",
            shortDescription: "Enhanced AI for complex text generation and understanding.",
            longDescription: "GPT-4 represents a significant advancement in natural language processing, offering superior understanding and text generation capabilities. This model is ideal for complex tasks involving language understanding, creative writing, and problem-solving. Its improved algorithms provide more contextually accurate and nuanced outputs, making it a powerful tool for a wide range of text-based applications."
        },
        3: {
            platform: 1,
            name: "gpt-4-1106-preview",
            normalName: "GPT-4 1106 Preview",
            shortDescription: "Preview version of GPT-4 with specific feature testing.",
            longDescription: "The GPT-4 1106 Preview is a specialized version of the GPT-4 model, likely incorporating new features or improvements for testing. This version provides insights into the potential advancements and capabilities of the upcoming GPT-4 model, focusing on enhanced language processing, speed, and efficiency. It's crucial for early adopters and testers who wish to explore the latest developments in AI language models."
        },
        6: { // The gpt-3.5-turbo model alias will be automatically upgraded from gpt-3.5-turbo-0613 to gpt-3.5-turbo-0125 on February 16th, that has 16,385 tokens
            platform: 1,
            name: "gpt-3.5-turbo-0613",
            trainedUntil: "Up to Sep 2021",
            contextWindow: 4096,
            normalName: "GPT-3.5 Turbo 0613",
            shortDescription: "A specific iteration of GPT-3.5 Turbo with unique features.",
            longDescription: "GPT-3.5 Turbo 0613 is a specific version of the GPT-3.5 Turbo model, likely including updates or specialized features unique to this iteration. It's designed for users who need the advanced language processing capabilities of GPT-3.5 but with specific enhancements or optimizations that this version offers. This model suits applications requiring a balance of speed, accuracy, and nuanced language understanding."
        },
        4: {
            platform: 1,
            name: "gpt-4-turbo-preview",
            normalName: "GPT-4 Turbo Preview",
            shortDescription: "Faster and efficient preview version of GPT-4.",
            longDescription: "GPT-4 Turbo Preview emphasizes speed and efficiency in language processing. This model is designed for scenarios requiring quick response times without compromising the quality of text generation. It's particularly suitable for interactive applications, offering faster performance while maintaining the advanced capabilities of the GPT-4 architecture."
        },
        5: {
            platform: 1,
            name: "gpt-3.5-turbo-instruct",
            normalName: "GPT-3.5 Turbo Instruct",
            shortDescription: "Fine-tuned for following instructions accurately.",
            longDescription: "GPT-3.5 Turbo Instruct is optimized for understanding and executing user instructions with high accuracy. This model is ideal for applications requiring precise adherence to user directives, offering enhanced capabilities in interpreting and responding to a wide range of commands. It's particularly useful in scenarios where clarity and accuracy in following instructions are critical."
        },

        7: {
            platform: 1,
            name: "gpt-3.5-turbo-16k-0613",
            normalName: "GPT-3.5 Turbo 16K 0613",
            shortDescription: "Enhanced version of GPT-3.5 for large-scale processing.",
            longDescription: "GPT-3.5 Turbo 16K 0613 is an enhanced version of the GPT-3.5 model, designed to handle larger datasets and more complex processing tasks. This model is ideal for applications that require extensive data analysis, large-scale language processing, and the ability to manage significant computational loads. It combines the robust language capabilities of GPT-3.5 with increased processing power."
        },
        8: {
            platform: 1,
            name: "gpt-3.5-turbo-16k",
            normalName: "GPT-3.5 Turbo 16K",
            shortDescription: "Advanced GPT-3.5 model for high-volume language tasks.",
            longDescription: "GPT-3.5 Turbo 16K represents a significant upgrade in handling high-volume language processing tasks. This model is tailored for scenarios that demand extensive computational resources and the ability to process large amounts of data efficiently. It is well-suited for enterprise-level applications, offering robust language understanding and generation capabilities at scale."
        },    
};

const languageList = {
    us: "English",      // United States for English
    es: "Spanish",      // Spain for Spanish
    fr: "French",       // France for French
    de: "German",       // Germany for German
    cn: "Chinese",      // China for Chinese
    jp: "Japanese",     // Japan for Japanese
    kr: "Korean",       // South Korea for Korean
    it: "Italian",      // Italy for Italian
    nl: "Dutch",        // Netherlands for Dutch
    pt: "Portuguese",   // Portugal for Portuguese
    sa: "Arabic",       // Saudi Arabia for Arabic
    tr: "Turkish",      // Turkey for Turkish
    se: "Swedish",      // Sweden for Swedish
    dk: "Danish",       // Denmark for Danish
    no: "Norwegian",    // Norway for Norwegian
    fi: "Finnish",      // Finland for Finnish
    pl: "Polish",       // Poland for Polish
    cz: "Czech",        // Czech Republic for Czech
    hu: "Hungarian",    // Hungary for Hungarian
    ro: "Romanian",     // Romania for Romanian
    gr: "Greek",        // Greece for Greek
    bg: "Bulgarian",    // Bulgaria for Bulgarian
    sk: "Slovak",       // Slovakia for Slovak
    si: "Slovenian",    // Slovenia for Slovenian
    hr: "Croatian",     // Croatia for Croatian
    rs: "Serbian",      // Serbia for Serbian
    lt: "Lithuanian",   // Lithuania for Lithuanian
    lv: "Latvian",      // Latvia for Latvian
    ee: "Estonian",     // Estonia for Estonian
    id: "Indonesian",   // Indonesia for Indonesian
    my: "Malay",        // Malaysia for Malay
    vn: "Vietnamese",   // Vietnam for Vietnamese
    th: "Thai",         // Thailand for Thai
    in: "Hindi",        // India for Hindi
    bd: "Bengali",      // Bangladesh for Bengali
    lk: "Tamil",        // Sri Lanka for Tamil
    in: "Telugu",       // India for Telugu (same as Hindi)
    in: "Marathi",      // India for Marathi (same as Hindi)
    in: "Gujarati",     // India for Gujarati (same as Hindi)
    in: "Kannada",      // India for Kannada (same as Hindi)
    in: "Malayalam",    // India for Malayalam (same as Hindi)
    in: "Punjabi",      // India for Punjabi (same as Hindi)
    pk: "Urdu",         // Pakistan for Urdu
    ph: "Filipino"      // Philippines for Filipino
};

// const languageList = { en: "English", es: "Spanish", fr: "French", de: "German", zh: "Chinese", ja: "Japanese", ko: "Korean", it: "Italian", nl: "Dutch", pt: "Portuguese", ar: "Arabic", tr: "Turkish", sv: "Swedish", da: "Danish", no: "Norwegian", fi: "Finnish", pl: "Polish", cs: "Czech", hu: "Hungarian", ro: "Romanian", el: "Greek", bg: "Bulgarian", sk: "Slovak", sl: "Slovenian", hr: "Croatian", sr: "Serbian", lt: "Lithuanian", lv: "Latvian", et: "Estonian", id: "Indonesian", ms: "Malay", vi: "Vietnamese", th: "Thai", hi: "Hindi", bn: "Bengali", ta: "Tamil", te: "Telugu", mr: "Marathi", gu: "Gujarati", kn: "Kannada", ml: "Malayalam", pa: "Punjabi", ur: "Urdu", tl: "Filipino" };

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
// router.beforeEach((to, from, next) => {
//   const myCookie = Cookies.get('myCookie');
//   if (to.path !== '/allownot' && to.path !== '/allow' && !myCookie) {
//     next('/allownot');
//   } else {
//     next();
//   } 
// })

// createApp(App).use(router).mount('#app')

import Globals from './func';

const app = createApp(App);

app.mixin(Globals);
app.config.globalProperties.$menuItems = menuItems;
app.config.globalProperties.$languageList = languageList;
app.config.globalProperties.$modelsList = modelsList;
app.config.globalProperties.$web3 = web3;

app.config.globalProperties.$USERDATA = { id:0, NAME: '', name:'', mail:'', raw:'' };
app.config.globalProperties.$DEBUG = process.env.VUE_APP_DEBUG === 'true';;

console.log('DEBUG / process.env', process.env.VUE_APP_DEBUG);

const abis = require('./ABI.json');
app.config.globalProperties.$abis = abis;

// app.mixin({
//   data() {
//     return {
//       Languages: [
//         { name: 'English', code: 'en', flag: '🇺🇸' },
//       ]
//     };
//   },
// });

app
.use(router)
.use(store)
.mount('#app')