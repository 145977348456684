<template>
  <router-view/>
</template>

<script>

export default {
  watch: {
    '$route'() {
      // console.log('route changed');
      // window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;
    }
  },

}
</script>

<style>

@import "@/assets/main.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
