<template>

    <div 
        class="
        fixed fix bottom-0 left-0 right-0 
        bg-zinc-900 md:hidden
        gap-4
        h-[75px] border-t border-zinc-800 px-4 pt-1 pl-5 text-zinc-400 
        grid grid-cols-5
        text-sm font-mono z-10"
        >
        <div v-for="(item, index) in menuItems" :key="index" class="">
            <router-link 
                    :to="item.goto"
                    v-if="index < 4" 
                    class="flex items-center grow gap-1 py-1 pt-2 text-center flex-col rounded-xl"
                    :class="{ 'bg-zinc-800  px-4': isActiveTab( item.name_active ) }" 
                >
                <!-- :class="{'opacity-50': index == 1, 'bg-zinc-600': isActiveTab( item.name ) }" -->
                    <div class=""><img :src="item.icon" alt="" class="h-s w-6"></div>
                    <div class="text-zinc-300 text-sm uppercase">{{ item.text }}</div>
            </router-link>
            <div v-if="index == 4" class="mt-1">
                <div @click="$emit('togglePanel')" class="mx-auto w-12 h-12 p-2 rounded-full md:hidden bg-[#435DF8] shadow-lg shadow-black pointer">
                    <div class="p-1">
                        <img src="/static/user.svg">
                    </div>
                </div> 
            </div>
        </div>
    </div>

</template>

<script>
export default {

  props: ['menuItems', 'showPanel'],

//     data() {
//         return {
//             menuItems: this.$menuItems,
//             showPanel: this.showPanel
//         }
//     },  
//     methods: {
//         togglePanel() {
//             this.showPanel = !this.showPanel;
//         }, 
//     }

}

</script>
