import { createRouter, createWebHashHistory } from 'vue-router'
//, createWebHistory

import Home from '@/views/Home.vue'
import Welcome from '@/views/Welcome.vue'

const routes = [

    {
      path: '/',
      name: 'welcome',
      component: Welcome
    },

    {
      path: '/home',
      name: 'home',
      component: Home
    },
      
    { path: '/adm', name: 'adm', component: () => import('@/views/Admin/Admin.vue'), meta: { title: 'ADM' },
      children: [
        { path: '', name: 'admUsers', component: () => import('@/views/Admin/AdminUsers.vue') },
        { path: 'user/:Id', name: 'admUser', component: () => import('@/views/Admin/AdminUser.vue'), props: true },
      ]   
    },
  
    { path: '/signup', name: 'signup', component: () => import('@/views/Sign/SignUp.vue'),
      children: [
        { path: '', name: 'signup0', component: () => import('@/views/Sign/Up/0-Email.vue') },
        { path: 'p', name: 'signupp', component: () => import('@/views/Sign/Up/0-Password.vue') },
        { path: '1', name: 'signup1', component: () => import('@/views/Sign/Up/1-Name.vue') },
        { path: '2', name: 'signup2', component: () => import('@/views/Sign/Up/2-Wallet.vue') },
        { path: '3', name: 'signup3', component: () => import('@/views/Sign/Up/3-Bridge.vue') },
      ]
    },

    { path: '/signin', name: 'signin', component: () => import('@/views/Sign/SignIn.vue'),
      children: [
        { path: '', name: 'signin0', component: () => import('@/views/Sign/In/Login.vue') },
        { path: '1', name: 'signin1', component: () => import('@/views/Sign/In/Password.vue') },
        { path: 'lost', name: 'lost', component: () => import('@/views/Sign/In/Lost.vue') },
      ]
    },

    { path: '/oauth/google', name: 'google', component: () => import('@/views/Sign/Google.vue') }, 
    { path: '/expired', name: 'expired', component: () => import('@/views/Sign/Expired.vue') },
    { path: '/exit', name: 'exit', component: () => import('@/views/Sign/Exit.vue') }, 

    { path: '/keys', name: 'keys', component: () => import('@/views/Keys/Keys.vue'), meta: { title: 'Keys' }, 
      children: [
        { path: '', name: 'keysList', component: () => import('@/views/Keys/KeysList.vue') },
      ] 
    },
    { path: '/key/:KeyId', name: 'key', component: () => import('@/views/Keys/Key.vue'), props: true,
      children: [
        { path: '', name: 'keyPosts', component: () => import('@/views/Keys/KeyPosts.vue'), props: true },
        { path: 'holds', name: 'keyHolds', component: () => import('@/views/Keys/KeyHolds.vue'), props: true },
        { path: 'holders', name: 'keyHolders', component: () => import('@/views/Keys/KeyHolders.vue'), props: true },
        { path: 'trades', name: 'keyTrades', component: () => import('@/views/Keys/KeyTrades.vue'), props: true },
      ],
  
    },
    { path: '/agents', name: 'agents', component: () => import('@/views/Agents/Agents.vue'), meta: { title: 'Agents' }, 
      children: [
        { path: '', name: 'agentsList', component: () => import('@/views/Agents/AgentsList.vue') },
        { path: ':AgentId', name: 'agent', component: () => import('@/views/Agents/AgentInfo.vue'), props: true},
        { path: ':AgentId/edit', name: 'agentEdit', component: () => import('@/views/Agents/AgentEdit.vue'), props: true},
        { path: ':AgentId/price', name: 'agentPrice', component: () => import('@/views/Agents/AgentPrice.vue'), props: true},
        { path: ':AgentId/tune', name: 'agentTune', component: () => import('@/views/Agents/AgentTune.vue'), props: true},
        { path: ':AgentId/set', name: 'agentSet', component: () => import('@/views/Agents/Setup.vue'), props: true, 
        children: [
            { path: '1', name: 'agentSet1', component: () => import('@/views/Agents/Setup/Setup1.vue'), props: route => ({ AgentId: route.params.AgentId }) },
            { path: '2', name: 'agentSet2', component: () => import('@/views/Agents/Setup/Setup2.vue'), props: route => ({ AgentId: route.params.AgentId }) },
            { path: '3', name: 'agentSet3', component: () => import('@/views/Agents/Setup/Setup3.vue'), props: route => ({ AgentId: route.params.AgentId }) },
            { path: '4', name: 'agentSet4', component: () => import('@/views/Agents/Setup/Setup4.vue'), props: route => ({ AgentId: route.params.AgentId }) },
            { path: '5', name: 'agentSet5', component: () => import('@/views/Agents/Setup/Setup5.vue'), props: route => ({ AgentId: route.params.AgentId }) },
          ]
        },
      ],
    },
    { path: '/agents/create', name: 'keyMint', component: () => import('@/views/Agents/Create.vue') },

    { path: '/user/:UserId', name: 'User', component: () => import('@/views/User/User.vue'), props: true,
      children: [
        { path: '', name: 'UserAgents', component: () => import('@/views/User/UserAgents.vue'), props: true},
        { path: 'keys', name: 'UserKeys', component: () => import('@/views/User/UserKeys.vue'), props: true},
        { path: 'trades', name: 'UserTrades', component: () => import('@/views/User/UserTrades.vue'), props: true},
      ],
    },

    // { path: '/disco', name: 'disco', component: () => import('@/views/Disco/Disco.vue') },
    // { path: '/feed', name: 'feed', component: () => import('@/views/Feed/Feed.vue') },

    { path: '/chat', name: 'chat', component: () => import('@/views/Chat/Chat.vue'), props: true,
        children: [
            { path: '', name: 'chatList', component: () => import('@/views/Chat/ChatList.vue'), props: true,
                children: [
                    { path: ':KeyId', name: 'chatMessages', component: () => import('@/views/Chat/ChatMessages.vue'), props: true },
                ]
            },
            // { path: ':ChatId', name: 'chatMessages', component: () => import('@/views/Chat/ChatMessages.vue'), props: true },
            // { path: 'new/:KeyId', name: 'chatNew', component: () => import('@/views/Chat/ChatNew.vue'), props: true },
        ]
    },
    { path: '/refs', name: 'refs', component: () => import('@/views/Refs/Refs.vue') },

    { path: '/docs/terms', name: 'terms', component: () => import('@/views/Docs/Terms.vue') },
    { path: '/docs/privacy', name: 'privacy', component: () => import('@/views/Docs/Privacy.vue') },

    ///// !!! WARNING !!! //////
    // { path: '/init', name: 'init', component: () => import('@/views/Sign/Init.vue') },
    // { path: '/hash', name: 'hash', component: () => import('@/views/Sign/Hash.vue') },
    
    { path: '/drop', name: 'drop', component: () => import('@/views/Drop/Drop.vue'),
      children: [ 
        { path: '', name: 'dropInfo', component: () => import('@/views/Drop/DropInfo.vue') },
      ] 
    },
  
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile/Profile.vue'),
      meta: { title: 'Profile' }, 
        children: [
          { path: '', name: 'ProfileDetails', component: () => import('@/views/Profile/ProfileDetails.vue') },
          // { path: 'inbox', name: 'Inbox', component: () => import('@/views/ProfileInbox.vue') },
          { path: 'posts', name: 'ProfilePosts', component: () => import('@/views/Profile/ProfilePosts.vue'), meta: { title: 'Posts' } },
         // use for X autopost { path: 'newpost', name: 'ProfilePostNew', component: () => import('@/views/Profile/ProfilePostNew.vue'), meta: { title: 'New Post' } },
          { path: 'trades', name: 'ProfileTrades', component: () => import('@/views/Profile/ProfileTrades.vue') },
          { path: 'collect', name: 'ProfileCollect', component: () => import('@/views/Profile/ProfileCollect.vue') },
          { path: 'holdings', name: 'ProfileHoldings', component: () => import('@/views/Profile/ProfileKeys.vue') },
          { path: 'holders', name: 'ProfileHolders', component: () => import('@/views/Profile/ProfileHolders.vue') },
          { path: 'wallet', name: 'ProfileWallet', component: () => import('@/views/Profile/ProfileWallet.vue') },
          { path: 'refill', name: 'ProfileRefill', component: () => import('@/views/Profile/ProfileRefill.vue') },
          { path: 'settings', name: 'ProfileSettings', component: () => import('@/views/Profile/ProfileSettings.vue') },
          { path: 'backup', name: 'WalletBackup', component: () => import('@/views/Profile/WalletBackup.vue') },
        ]
    },
  
    {
        path: '/:catchAll(.*)',
        component: () => import('@/views/NoPage.vue'),
    },

    // close access for cookies
    {
      path: '/allow',
      name: 'allow', 
      component: () => import('@/views/Allow.vue') 
    },
    {
      path: '/allownot',
      name: 'allownot', 
      component: () => import('@/views/AllowNot.vue') 
    },
    { path: '/but', name: 'but', component: () => import('@/views/Buttons.vue') },
    { path: '/ass', name: 'ass', component: () => import('@/views/Assistant.vue') },
    
  ];

  const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
      return { top: 0 };
    },
  });

  router.afterEach((to, from) => {
    // console.log('AFTER EACH');
    if(to.meta.title)
    document.title = 'QUOTA - '+to.meta.title;
    else document.title = 'QUOTA';
    // Vue.nextTick(() => {
    //   console.log('tick');
    //   document.title = to.meta.title || 'Default Title';
    // });
  });

  router.beforeEach((to, from, next) => {
    const publicPages = [
        '/', 
        '/signin', 
        '/signin/0', 
        '/signin/1', 
        '/signin/lost', 
        '/signup', 
        '/signup/0', 
        '/signup/p', 
        '/oauth/google', 
        '/exit',
        '/docs/terms',
        '/docs/privacy', 
    ]; 
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/signin');
    } else {
      next();
    }
  });

  export default router;