import { createStore } from "vuex";
import { auth } from "./auth.module";

const store = createStore({
  modules: {
    auth,
  },
  state: {
    signUpEmail: '',
    signInEmail: '',
    signUpCode: '',
    signUpUTM: '',
  },
  mutations: {
    setSignUpEmail(state, email) {
      state.signUpEmail = email;
    },
    setSignInEmail(state, email) {
      state.signInEmail = email;
    },
    setSignUpCode(state, code) {
      state.signUpCode = code;
    },
    setSignUpUTM(state, utm) {
      state.signUpUTM = utm;
    },
  },
});

export default store;