<template>
    <div class="overflow-hidden ">
                            
        <div class="w-[2300px] items-start gap-5 flex h-36 marquee">

            <!-- Keys List Scrolling -->
            <div to="/dash/user" v-for="(item, index) in keysItems" :key="index" 
            class="flex w-[260px] rounded-3xl bg-zinc-900 px-3 md:px-5 py-4 md:py-4 text-center border border-zinc-600 " 
            >
                <div class="w-3/3">
                    <div class="w-8 mx-auto"><img src="/static/ava.png" class="w-8 h-8 rounded-full border-4 border-zinc-600"></div>
                    <div class="text-lg font-bold my-2 h-6 overflow-hidden shrinked-b">{{ item.name }}</div>
                    <!-- <div class="text-sm text-zinc-400 mt-23">{{ item.bio }}</div> -->
                    <!-- <div class="text-base brand uppercase text-white mt-2">Holds {{ item.holds }} keys</div> -->
                </div>
                <div class="ml-2 w-2/3 ">

                    <div class="rounded-xl p-3 px-4 text-sm md:text-base ">
                        <div class="mb-1 uppercase text-zinc-500">Pays /30D</div>
                        <div class="flex-nowrap ext-base md:text-lg font-mono text-zinc-100">0.0068Ξ</div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="overflow-hidden ">
        <div class="w-[2300px] mt-3 mb-5 items-start gap-5 flex h-36 marquee_l">

            <!-- Keys List Scrolling -->
            <div to="/dash/user" v-for="(item, index) in keysItems" :key="index" 
            class="flex w-[260px] rounded-3xl bg-zinc-900 px-3 md:px-5 py-4 md:py-4 text-center border border-zinc-600 " 
            >
                <div class="w-3/3">
                    <div class="w-8 mx-auto"><img src="/static/ava.png" class="w-8 h-8 rounded-full border-4 border-zinc-600"></div>
                    <div class="text-lg font-bold my-2 h-6 overflow-hidden shrinked-b">{{ item.name }}</div>
                    <!-- <div class="text-sm text-zinc-400 mt-23">{{ item.bio }}</div> -->
                    <!-- <div class="text-base brand uppercase text-white mt-2">Holds {{ item.holds }} keys</div> -->
                </div>
                <div class="ml-2 w-2/3 ">

                    <div class="rounded-xl p-3 px-4 text-sm md:text-base ">
                        <div class="mb-1 uppercase text-zinc-500">Pays /30D</div>
                        <div class="flex-nowrap ext-base md:text-lg font-mono text-zinc-100">0.0068Ξ</div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<style>
    .marquee { animation: marquee 100s linear infinite; }
    @keyframes marquee { 0% { transform: translateX(0); } 100% { transform: translateX(-100%); }}
    .marquee_l { transform: translateX(-100%); animation: marquee_l 100s linear infinite; }
    @keyframes marquee_l { 0% { transform: translateX(-80%); } 100% { transform: translateX(0%); } }  
</style>


<script>
export default {

    
    data() {
        return {
            
            keysItems: [
                { verified:'1', id:'23', holds: '1', bio: 'Bio that tells a lot about this particular user account.', holders_change: '4', changed: '43', name: 'Scientist', change:'green', holders: '32', price: '0.14', rate: '4.2', answer_in: '2h', fund1: '0.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3', follows: '657234' },
                { verified:'1', id:'23', holds: '22', bio: 'Bio that tells a this particular user account.', holders_change: '-2', changed: '-23', name: 'Mad Scientist', change:'green', holders: '32', price: '3.034', rate: '1.2', answer_in: '2h', fund1: '1.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3', follows: '34'  },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '12', bio: 'Bio that tells a lot abou lot aboutt this particular user account.', holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { verified:'1', id:'23', holds: '432', bio: 'Bio that ot about thisot about thistells a l particular user account.', holders_change: '-81', changed: '-65', name: 'Cientis Mad ', change:'red', holders: '32', price: '0.71', rate: '4.2', answer_in: '2h', fund1: '0.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3', follows: '3234'  },
                { verified:'1', id:'23', holds: '54', bio: 'Bio that tells a lot ticular usabout this prer accorer accorer accoaunt.', holders_change: '23', changed: '12', name: 'Vefieifed', change:'green', holders: '32', price: '0.3044', rate: '3.6', answer_in: '2h', fund1: '3.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '234' },
                { verified:'1', id:'23', holds: '1', bio: 'Bio that tells a lot about thular user account.', holders_change: '4', changed: '0.5', name: 'Fundy Fdfllle Fdfllle Fdfllle', change:'red', holders: '32', price: '0.034', rate: '0', answer_in: '2h', fund1: '34.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '34' },
            ]

        }
    }, 

}
</script>