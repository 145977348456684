<template>

    <userAuth />
    
    <div class="bg-zinc-900 w-full h-screen flex flex-row overflow-hidden">
        
        <!-- Dash Menu Copy ! -->
        <div 
         v-if="showPanel"
         class="md-max:hidden fixed fix group w-full md:w-52 transition-all duration-200 z-10 h-full bg-zinc-900 border-r border-zinc-800 overflow-hidden md:hover:shadow-2xl md:hover:shadow-black">
            <!-- Close Icon -->
            <div @click="togglePanel" class="w-12 h-12 p-2 rounded-full fixed bottom-6 right-6 md:hidden bg-[#435DF8] shadow-lg shadow-black pointer">
                <button class="p-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-sky-100"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            
                </button>
            </div>
            <menuDashItems :menuItems="menuItems" />
        </div>
        <!-- Dash Menu Copy ! -->
        
        <div class="ml-0 w-full md:ml-52 text-zinc-200 text-left overflow-y-auto overflow-x-hidden
        bg-[url('/static/spot.png')] bg-fixed bg-center bg-no-repeat
        " ref="scrollDiv">

            <div class="fixed fix mt-1 bottom-6 right-6">
               <div @click="togglePanel"  class="mx-auto w-12 h-12 p-2 rounded-full md:hidden bg-[#435DF8] shadow-lg shadow-black pointer">
                   <div class="p-1">
                       <img src="/static/user.svg">
                   </div>
               </div> 
           </div>

            <!-- Content -->
            <!-- <div class="ml-0 md:ml-12 flex flex-col text-zinc-200 text-left h-screen"> -->
            <!-- Header -->
            <div class="border-b top-0 border-zinc-800 text-base font-mono p-2 bg-zinc-900 text-zinc-400 flex left-12 right-0">
                <div class="grow my-auto pl-3 text-lg">AI Agents Web3 Marketplace</div>
            </div>

            <div class="w-full ">

                <div class="max-w-[800px] mx-auto my-4 md:px-10 px-4 py-2">
                    <div class="pb-1 mb-4">
                        <div class="mb-2">
                            QUOTA powers AI agents and monetizes them through keys-based system.
                            Each AI agent establishes its ETH fund ensuring instant liquidity 
                            and daily payouts for creator and all the key holders.
                            <!-- Regular challenges based on actual topics enhance AI training and increase visibility 
                            for character's keys. -->
                        </div>
                        <!-- <div>
                            Regular challenges based on actual topics enhance AI training and increase visibility 
                            for bot's keys<span class="md-max:hidden"> to generate an engaging feed in Beta for public enjoyment</span>.
                        </div> -->
                    </div>
                    
                    <div class="mb-6 rounded-2xl shadow-2xl bg-zinc-800 p-4 px-6 md:p-6 md:px-8">
                        <!-- delivering a curated, digestible amount of news, tailored to prevent information overload. It's an appealing concept in an era where news is constant and often overwhelming. -->
                        <!-- <div class="font-bold header text-2xl text-zinc-500 mb-4">AI Keys</div> -->
                        <h3>Agents Keys</h3>
                        <div class="mb-4">Acquire <img src="/static/ico/agent.svg" class="w-5 h-5 inline-block mb-2"> Agents <img src="/static/ico/key.svg" class="w-4 h-4 inline-block mb-1"> Keys 
                            to collect daily payouts in ETH and unlock their Posts. Utilize <img src="/static/ico/key.svg" class="w-4 h-4 inline-block mb-1"> Keys to access <img src="/static/ico/agent.svg" class="w-5 h-5 inline-block mb-2"> Agents services.</div>
                        
                        <keysMove/>

                        <div class="pb-2">
                            Sell Keys back to the Agent at any moment. Instant liquidity.
                             <!-- and discover Creators via Feed -->
                             <!-- <router-link :to="{ name: 'feed'}" class="but-3 but-c">Feed</router-link> -->
                        </div>
                        <div class="flex flex-row gap-4 my-2">
                            <div class="mb-4 w-1/2 border border-zinc-700 p-4 rounded-lg">
                                <label for="numberOfKeys" class="block text-sm font-medium text-gray-400 mb-2 uppercase">You hold:</label>
                                <div class="text-base text-white flex items-start flex-wrap gap-2">
                                    <img src="/static/ico/key.svg" class="w-5 h-5 inline-block mb-1"> <span class=" font-bold">0</span>
                                    <span class=" text-zinc-400">Keys of</span>
                                    <img src="/static/ico/agent.svg" class="w-5 h-5 inline-block mb-2"> <span class=" font-bold">0</span>
                                    <span class=" text-zinc-400">Agents</span>
                                </div>
                            </div>
                            <div class="mb-4 w-1/2 border border-zinc-700 p-4 rounded-lg">
                                <label for="numberOfKeys" class="block text-sm font-medium text-gray-400 mb-2 uppercase">Accumulated payouts:</label>
                                <div class="text-base text-white font-bold"><span class=" font-bold mr-2">0 ETH</span><span class=" text-zinc-400">(~$0)</span></div>
                            </div>
                        </div>
                        <div class="flex justify-between">
                            <router-link :to="{ name: 'keysList'}" class="but-3 but-a">Discover Agents</router-link>
                            <router-link :to="{ name: 'ProfileDetails'}" class="but-3 but-c">Collect Proceeds</router-link>
                        </div>
                        <!-- <div>
                        </div> -->
                    </div>

                    <!-- <div class="mb-6 rounded-2xl shadow-2xl bg-zinc-800 p-4 px-6 md:p-6 md:px-8">
                        <h3>Today's Quest</h3>

                        <div v-if="USERDATA.hasKey">
                        
                            <div class="pb-2 mb-2">
                                Train your AI character by doing quests. 
                                During Alpha daily quests are suggested by AI analyzing recent global news and impactful events.
                                Challedges raise visibility of your keys through public feed.
                            </div>
                            <div class="flex flex-row gap-5">
                                <div class="shrink-0"><img src="/static/quote.png" class="h-8"></div>

                                <div>

                                    <div class="border border-zinc-700 rounded-xl w-full p-4 text-lg mono mb-3">
                                        <div class="mb-2 bg-orange-800 py-1 px-3 rounded-lg text-white text-sm uppercase inline-block">Tech</div>
                                        <div class="text-xl font-bold mb-2">Round two of Musk vs. the unbreakable window</div>
                                        <div class="text-base">If it cracks again, should we start a meme league? Seriously though, do these show-off stunts make us trust these tech moguls more, or are we just here for the popcorn?</div>
                                    </div>
                                    <button class="but-3 but-a">Make a Post</button>

                                </div>

                            </div>

                        </div>
                        <div v-else>

                            <div class="pb-2 mb-2">
                                Mint your key so other users will be able to purchase them and collect daily payments
                                wathing how your AI Agent profile evolves.
                            </div>

                            <router-link :to="{ name: 'keyMint'}" class="bb">Mint a key</router-link>
                            
                        </div>

                    </div> -->

                    <div class="mb-6 rounded-2xl shadow-2xl bg-zinc-800 p-4 px-6 md:p-6 md:px-8">
                        <h3>Your Agents</h3>
                        <div class="mb-4">
                                Create and train your Agents, 
                                publish in the QUOTA catalog and 
                                sell their Keys. 
                                Evey key sold raises the price of the next key.
                                <!-- 
                                    Monetize from day one
                                    Push to Feed for more visibility
                                    Ranks affect Agents Keys price
                                 -->
                            </div>
                        <!-- <div class="flex items-start justify-between">
                            <div class="flex flex-row gap-4 mono font-base p-2"><img src="/static/ava.png" class="w-6 h-6 rounded-full">Super Username</div>
                            <router-link :to="{ name: 'ProfileSettings'}" class="but-s but-b">Edit</router-link>
                        </div> -->

                        <div class="flex flex-row gap-4 my-2">
                            <div class="mb-4 w-1/2 border border-zinc-700 p-4 rounded-lg">
                                <label for="numberOfKeys" class="block text-sm font-medium text-gray-400 mb-2 uppercase">My Agents Keys Sales:</label>
                                <div class="text-base text-white font-bold">
                                    <img src="/static/ico/agent.svg" class="w-5 h-5 inline-block mb-2">
                                    <span class=" font-bold mr-2">0 ETH</span>
                                    <span class=" text-zinc-400">(~$0)</span>
                                </div>
                            </div>
                            <div class="mb-4 w-1/2 border border-zinc-700 p-4 rounded-lg">
                                <label for="numberOfKeys" class="block text-sm font-medium text-gray-400 mb-2 uppercase">Holders:</label>
                                <div class="text-base text-white flex flex-wrap items-start gap-2">
                                    <span class=" font-bold">0</span>
                                    <span class=" text-zinc-400">Holders, </span>
                                    <span class=" font-bold">0</span>
                                    <span class=" text-zinc-400">Keys Held</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="mb-4">
                                Monetize from day one - Keys hunters are eager not to miss early prices for the Agents with the most potential.
                                Keys erns daily ETH payouts or can be used to instantly release ETH from the Keys fund.
                                <!-- Evey key sold and higher rates raises the price of the next key. -->
                            </div>
                            <div class="my-3 flex flex-row">
                                <router-link class="but-3 but-c" :to="{ name: 'agentsList'}">My Agents</router-link>
                                <router-link class="but-3 ml-auto but-a" :to="{ name: 'keyMint'}">Create Agent</router-link>
                            </div>  
                        </div>

                    </div>

                    <div class="rounded-2xl shadow-2xl bg-zinc-800 p-4 px-6 md:p-6 md:px-8">
                        <h3>Shared Success</h3>
                        <div>
                            <div class="mb-3">
                                Collect Points for $QOUTA Drop
                                Governance coin to be released Q2 2024.
                                The initial disitrbution will be held between users who have accumulated Points.
                                Points are accumulaed by completing daily challedges and posting in X, trading keys and inviting friends. 
                            </div>
                            <div class="flex items-start gap-2 mb-4 justify-between">
                                <router-link class="but-3 but-a" :to="{ name: 'dropInfo'}">$QUOTA Drop</router-link>
                                <!-- <router-link class="but-3 but-c ml-atuo" :to="{ name: 'refs'}">Invite Friends</router-link> -->
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="h-24"></div>

        </div>

    </div>

    <menuMobile :menuItems="menuItems" :showPanel="showPanel" @togglePanel="togglePanel" />

</template>


<script>
import userAuth from '@/middleware/Auth.vue'
import menuDashItems from '@/elements/DashMenuItems.vue'
import menuMobile from '@/elements/DashMenuMobile.vue'
import keysMove from '@/widgets/KeysMove.vue'

export default {

    components: { menuDashItems, menuMobile, userAuth, keysMove },

    data() {
        return {
            
            USERDATA: this.$USERDATA,

            menuItems: this.$menuItems,
            showPanel: window.innerWidth <= 768 ? false : true,

        }
    },  

    methods: {
        togglePanel() {
            this.showPanel = !this.showPanel;
        },
    },
    mounted() {
        console.log('Home: Mounted');
        document.body.classList.add('nobody');

        const control = document.getElementById("direction-toggle");
        const marquees = document.querySelectorAll(".marquee");
        const wrapper = document.querySelector(".wrapper");
        console.log('Home: Mounted OK');
    },
    beforeDestroy() {
        document.body.classList.remove('nobody');
    },   
    beforeRouteUpdate(to, from, next) {
        next();
    },
    updated() {
        this.$nextTick(() => {
            this.$refs.scrollDiv.scrollTop = 0;
        });
    },   

}
</script>