import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL; 

console.log('API_URL: ', API_URL);

class AuthService {

  login(user) {
    return axios
      .post(API_URL + 'auth/signin', {
        // username: user.username,
        email: user.email,
        password: user.password
      })
      .then(response => {
        // console.log('response >>> ', response.data.accessToken);
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        // console.log('response >>> ', response.data);
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    
  }

  refresh() {
    let user = JSON.parse(localStorage.getItem('user'));
    console.log('retreiving user from local storage >>> ', user.accessToken);
    if (user && user.accessToken) {
      console.log('refresh >>> ', user.refreshToken);
      return axios
        .post(API_URL + 'auth/refresh-token', {
          token: user.refreshToken
        })
        .then(response => {
          console.log('response >>> ', response.data.accessToken);
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data));
          }
          console.log('refreshed >>> ', response.data);
          return response.data;
        });
      }
  }

  register(user) {
    console.log('mail >>> ', user.email);
    return axios
    .post(API_URL + 'auth/signup', {
      // username: user.username,
      email: user.email,
      password: user.password,
      password2: user.password
    })
    .then(response => {
      console.log('response >>> ', response.data.accessToken);
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      console.log('response >>> ', response.data);
      return response.data;
    });

  }
}

export default new AuthService();
