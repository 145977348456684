<template>

        <!-- Logo -->
        <div class="p-1">
            <div class="md:w-52 w-full">
                <div class="py-2 px-2">
                    <!-- <button @click="toggleDebug" :class="{ 'switch-on': DEBUG, 'switch-off': !DEBUG }" class="bb">{{ DEBUG ? 'ON' : 'OFF' }}</button> -->
                    <!-- Mobile -->
                    <div class="bg-zinc-800 rounded-xl p-5 md:hidden">
                        <!-- <div class="flex flex-row">
                            <div class="border uppercase text-zinc-500 text-sm border-zinc-600 rounded-lg px-2 ml-auto p-2 mt-2 flex gap-2"><img src="/static/exit.svg" alt="" class="h-4 w-4 opacity-40 hover:opacity-95"> Log out</div>
                        </div> -->
                        <div class="flex p-4 w-full">
                            <router-link to="/home" class="w-full justify-center flex">
                                <img src="/static/quota.svg" alt="" class="fill-zinc-500 h-5 mx-ato">
                            </router-link>
                        </div>
                        <div to="/profile" class="flex flex-col items-center gap-3 py-2 px-2 text-center justify-center">
                            <img src="/static/ava.png" alt="" class="h-12 w-12 rounded-full">
                            <div class="grow w-full text-ceter px-10 my-2 overflow-hidden text-zinc-300 text-2xl">
                                <span v-html="USERDATA.NAME"></span>
                            </div>
                            <div class="text-white flex flex-row gap-2">
                                <div>{{  USERDATA.mail }}</div>
                                <router-link :to="{ name: 'exit'}" class="w-6 h-6 ml-auto pointer mt-2"><img src="/static/exit.svg" alt="" class="h-4 w-4 opacity-40 hover:opacity-95"></router-link>
                            </div>
                            <!-- <div class="bg-zinc-600 rounded-lg px-4 flex gap-2 p-2 mono text-white text-base uppercase">
                                <b>{{ walletBalance }}</b> ETH
                            </div> -->
                            <!-- <div>
                                <router-link :to="{ name: 'profileWallet' }" class="but-5 but-d">Open Wallet</router-link>
                            </div> -->
                            <!-- Ξ -->
                        </div >
                    </div>
                    <!-- Desktop when Panel opens -->
                    <div v-if="$route.name != 'home'" class="hidden md:block mt-1">
                        <div class="hidden group-hover:flex h-6">
                            <router-link to="/home">
                            <img src="/static/quota.svg" alt="" class=" h-5 mt-1">
                            </router-link>
                        </div>
                    </div>
                    <!-- Desktop when Panel closed -->
                    <!-- Home Show Orange Logo -->
                    <div v-if="$route.name === 'home'" class="hidden md:block mt-2">
                        <div  class="w-52 h-6">
                            <img src="/static/quota.svg" alt="" class=" h-5 mt-2">
                        </div>
                    </div>
                    <!-- Not Home Show Dark Q Letter Logo Only -->
                    <div v-else class="w-6 h-6 hidden md:flex group-hover:hidden">
                        <!-- <img src="/static/q.svg" alt="" class="h-6 w-6 fill-zinc-500"> -->
                        <svg width="31" height="25" viewBox="0 0 31 25" xmlns="http://www.w3.org/2000/svg" class="fill-zinc-500">
                            <path d="M30.336 11.36C30.336 14.4533 29.2267 17.0027 27.008 19.008L30.336 24.64H23.072C22.112 24.64 21.1627 24.4587 20.224 24.096C19.3067 23.7333 18.5387 23.232 17.92 22.592C16.9173 22.6987 16 22.752 15.168 22.752C12.3093 22.752 9.728 22.3147 7.424 21.44C5.14133 20.5653 3.328 19.2747 1.984 17.568C0.661333 15.84 0 13.7707 0 11.36C0 8.97067 0.661333 6.92267 1.984 5.216C3.328 3.50933 5.152 2.21867 7.456 1.344C9.76 0.448 12.3307 0 15.168 0C18.0053 0 20.576 0.448 22.88 1.344C25.184 2.21867 26.9973 3.50933 28.32 5.216C29.664 6.92267 30.336 8.97067 30.336 11.36ZM15.168 20.864C16.0853 20.864 16.7573 20.4587 17.184 19.648C17.5467 19.0293 17.792 17.9307 17.92 16.352C17.984 15.52 18.016 13.856 18.016 11.36C18.016 9.184 17.9627 7.40267 17.856 6.016C17.792 4.736 17.5573 3.76533 17.152 3.104C16.7253 2.29333 16.064 1.888 15.168 1.888C14.2933 1.888 13.632 2.29333 13.184 3.104C12.8213 3.744 12.5867 4.71467 12.48 6.016C12.3733 7.06133 12.32 8.84267 12.32 11.36C12.32 13.9413 12.3627 15.6053 12.448 16.352C12.5547 17.9093 12.7893 19.008 13.152 19.648C13.6 20.4587 14.272 20.864 15.168 20.864Z" />
                        </svg>
                    </div>                        
                </div>
            </div>
        </div>
        <!-- Menu items -->
        <div class="p-5 md:p-1 z-10 bg-zinc-900 absolute max-md:hidden text-lg md:text-xl">
            <div v-for="(item, index) in menuItems" :key="index" class="w-full md:w-52 text-zinc-100">
                <!-- :class="{ 'act': isActiveTab( item.name ) }" -->
                <router-link 
                :to="item.goto"
                v-if="index < 4" 
                class="flex items-center gap-3 hover:bg-zinc-800 p-4 md:py-2 md:px-2 max-md:uppercase"
                >
                    <img :src="item.icon" alt="" class="h-6 w-6 opacity-30 group-hover:opacity-100">
                    <span>{{ item.text }}</span>
                    <!-- :class="{'text-zinc-400': index == 1}" -->
                    <div v-if="item.num > 1">{{ item.num }}</div>
                </router-link>
            </div>
        </div>
        <!-- Profile -->
        <div class="p-4 md:p-0 absolute bottom-1 left-1 right-1 w-52 z-9 text-zinc-100 text-lg md:text-xl">
            <div v-for="(item, index) in menuItems" :key="index">
                <router-link 
                 :to="item.goto"
                 v-if="index >= 4 && index < 8" 
                 @click="handleClick"          
                 class="flex items-center gap-3 hover:bg-zinc-800 p-4 md:py-2 md:px-2 max-md:uppercase"
                 >
                    <img :src="item.icon" alt="" class="h-6 w-6 opacity-30 group-hover:opacity-100 hover:opacity-100">
                    <span>{{ item.text }}</span>
                    <div v-if="item.num > 1">{{ item.num }}</div>
                </router-link>
            </div>

            <!-- <router-link to="/profile" class="flex items-center gap-3 hover:bg-zinc-800 md:py-2 md:px-2 p-4">
                <img src="/static/online.svg" alt="" class="h-6 w-6">
                <span class="text-zinc-300">Profile</span>
            </router-link >
            <router-link to="/profile" class="flex items-center gap-3 hover:bg-zinc-800 py-2 px-2">
                <img src="/static/online.svg" alt="" class="h-6 w-6"><span class="text-zinc-300">My Posts</span>
            </router-link >
            <router-link to="/profile" class="flex items-center gap-3 hover:bg-zinc-800 py-2 px-2">
                <img src="/static/online.svg" alt="" class="h-6 w-6"><span class="text-zinc-300">Messages</span>
            </router-link >
            <router-link to="/profile" class="flex items-center gap-3 hover:bg-zinc-800 py-2 px-2">
                <img src="/static/online.svg" alt="" class="h-6 w-6"><span class="text-zinc-300">Profile</span>
            </router-link > -->
            <div class="md:flex flex-row hidden items-center gap-3 py-2 px-2 text-lg">
                <router-link :to="{ name: 'ProfileSettings'}" class="grow flex gap-3 whitespace-nowrap w-40 overflow-hidden">
                    <img src="/static/ava.png" alt="" class="h-6 w-6 rounded-full">
                    <span class="text-zinc-300">{{ USERDATA.mail }}</span>
                </router-link>
                <router-link :to="{ name: 'exit'}" class="w-6 h-6 ml-auto pointer mt-2"><img src="/static/exit.svg" alt="" class="h-4 w-4 opacity-40 hover:opacity-95"></router-link>
            </div>
        </div>

</template>

<script>
import ProfileService from "@/services/profile.service";
// import { walletService } from '@/services/wallet.service';

export default {
    props: ['menuItems'],
    // inject: ['USERDATA'],
    data() {
        return {
            userdata: '',
            USERDATA: this.$USERDATA,
            DEBUG: this.$DEBUG,

            // TheWallet: new walletService(this.$web3, this.$abis),
            // web3: this.$web3, // GLOBAL WEB3
            // walletBalance: '-',
            // checkingBalance: false,

        }
    },
    methods: {
        handleClick() {
            if (window.innerWidth <= 768) {
                this.$emit('togglePanel')
            }
        },
        // toggleDebug() {
        //     this.DEBUG = !this.DEBUG;
        //     this.$globalState.DEBUG = this.DEBUG;
        // }
    },
    mounted() {

        

    },
    created() {


    },
  
}

</script>

<!-- <style>
    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
    .refreshing {
    animation: spin 2s linear infinite;
    opacity: 0.7;
    }
</style> -->