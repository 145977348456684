export default {
    data() {

        return {

            Languages: [
                { name: 'English', code: 'en', flag: '🇺🇸' },
                { name: 'Spanish', code: 'es', flag: '🇪🇸' },
                { name: 'French', code: 'fr', flag: '🇫🇷' },
                { name: 'German', code: 'de', flag: '🇩🇪' },
                { name: 'Italian', code: 'it', flag: '🇮🇹' },
                { name: 'Portuguese', code: 'pt', flag: '🇵🇹' },
                { name: 'Russian', code: 'ru', flag: '🇷🇺' },
                { name: 'Chinese', code: 'zh', flag: '🇨🇳' },
                { name: 'Japanese', code: 'ja', flag: '🇯🇵' },
                { name: 'Korean', code: 'ko', flag: '🇰🇷' },
            ],

            Characters: [
                'Funny', 'Calm', 'Angry', 'Honest', 'Confident', 'Generous', 'Courageous', 'Adventurous', 'Ambitious', 'Compassionate', 'Creative', 'Determined', 'Patient', 'Open-minded', 'Optimistic', 'Reliable', 'Self-disciplined', 'Thoughtful', 'Trustworthy', 'Understanding', 'Passionate', 'Persistent', 'Responsible', 'Self-motivated', 'Selfless', 'Witty'
            ],

        }
    },
    methods: {

        // clearUserdata() {
        //   this.USERDATA = {};
        // },

        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
            .then(() => {
                // console.log('Text copied to clipboard');
            })
            .catch(err => {
                // console.error('Failed to copy text: ', err);
            });
        },
        
        isActiveTab(tabName) {
            return this.$route.name === tabName;
        },

        truncateName(name) {
            if (typeof name === 'string') {
                return name.length > 20 ? name.substring(0, 20) + '...' : name;
            } else {
                return name;
            }
        },

        truncateNumber(number) {
            if (typeof number === 'string') {
                return number.length > 4 ? number.substring(0, 4) + '...' : number;
            } else {

                number=number.toString(4);
                return number.length > 4 ? number.substring(0, 4) + '...' : number;
            }
        },

        fromWei(weiValue, decimals = 2) {
            if(weiValue === undefined) return weiValue;
            if(typeof weiValue === 'number') {
                weiValue = String(weiValue);
            }
            if(typeof weiValue === 'string') {
                // weiValue = weiValue.split('.')[0]; // Remove decimal part
                weiValue = weiValue.replace(/[^\d]/g, ''); // Remove non-digit characters
            }
            // if(!/^\d+$/.test(weiValue)) {
            //     console.error('Invalid BigInt syntax:', weiValue);
            //     return weiValue;
            // }

            // const weiNumber = Number(weiValue);
            const wei = BigInt(weiValue);
            const etherInWei = BigInt('1000000000000000000');
            const ether = wei * BigInt(Math.pow(10, decimals)) / etherInWei;
        
            // Convert the final result to a Number and then to a fixed decimal string
            const etherNumber = Number(ether) / Math.pow(10, decimals);
            return etherNumber.toFixed(decimals);
        },

        toUsd(num, decimals = 0) {
            if (typeof num === 'string') {
                num = parseFloat(num);
            }
            
            return '$'+(num * 2200).toFixed(decimals);
        },

        checkEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        
        formatName(tname, tid) {
            if (tname === '') {
                return '<span class=text-zinc-600>#' + tid + '</span>';
            } else {
                return tname; // + '<span class=text-zinc-600>#' + tid + '</span>';
            }
        },

        formatWallet (walletaddress, letters = 6) {
            // if(wallet.>40) {
            if(letters>30 || letters<2 || !letters) letters=6;
            return walletaddress.substring(0, (2+letters))+'...'+walletaddress.substr((42-letters));
            //} else return wallet;
        },

        formatDate(date) {
            // Create a new Date object for the current date
            const today = new Date();
            // Create a new Date object for the given date
            const givenDate = new Date(date);
            // Calculate the difference in milliseconds
            const diffInMs = Math.abs(today - givenDate);
            // Convert the difference from milliseconds to days, hours, and minutes
            const diffInDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000));
            const diffInHours = Math.floor(diffInMs / (60 * 60 * 1000));
            const diffInMinutes = Math.floor(diffInMs / (60 * 1000));

            // Return the difference in days, hours, or minutes
            if (diffInDays > 0) {
                return diffInDays + ' days';
            } else if (diffInHours > 0) {
                return diffInHours + ' hours';
            } else {
                return diffInMinutes + ' minutes';
            }
        },

        formatDate2(dateStr) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

            const date = new Date(dateStr);
            const dayName = days[date.getDay()];
            const day = date.getDate();
            const monthName = months[date.getMonth()];
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${dayName} ${monthName} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;
        },

        getTextContent(contentElements) {
            if (Array.isArray(contentElements)) {
            const textElement = contentElements.find(element => element.type === 'text');
            return textElement ? textElement.text.value : '';
            }
            return '';
        },
    
  },
};