import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class ProfileService {

/* + 2FA

POST
/profile/password/change

POST
/auth/restore
Restore password
POST
/auth/restore/new
Set new password

POST
/profile/two-factor/enable
Enable 2FA
POST
/profile/two-factor/disable
Disable 2FA
GET
/profile/two-factor/qr
2FA - get QR code

GET
/auth/google/login
GET
/auth/google/redirect

*/

async getUserData() {
  return await axios.get(API_URL + 'profile', { headers: authHeader() });
}
// Save user profile
// saveUserData() {
//   return axios.post(API_URL + 'profile', { headers: authHeader() });
// }

// WARING --- MOVED TO WALLET SERVICE! //
 async getMnemonic() {
  console.log('getMnemonic');
  return await axios.get(API_URL + 'profile/mnemonic', { headers: authHeader() })
  .catch(error => {
    console.error(error);
  });
  // .then(response => {
  //   console.log('getMnemonic response=',response.data);
  //   return response.data;
  //   // response.data contains the response body
  //   // console.log();
  // })
}

getCollect(keyId) {
    return axios.get(API_URL + 'profile/collect', { headers: authHeader() });
}
getCollectTotal(keyId) {
    return axios.get(API_URL + 'profile/collect/all', { headers: authHeader() });
}


// listMyCodes(list_page, list_take) {
//   console.log('listPosts > ', list_page, list_take);
//   return axios.post(API_URL + 'profile/refcodes', { 
//     'page': list_page, 
//     'take': list_take, 
//     'onlyTotal': false, 
//   }, { headers: authHeader() })
//   // .catch(error => {
//   //   if (error.response && error.response.status === 400) {
//   //     return Promise.reject(error.response.data.message);
//   //   }
//   //   return Promise.reject(error.response.status);
//   // });
// }

// async listCodes(list_page, list_take) { // list of my codes
//   console.log('listCodes', list_page, list_take);
//   return await axios.post(API_URL + 'profile/refcodes', { 
//     'page': list_page, 
//     'take': list_take, 
//     'onlyTotal': false, 
//   }, { headers: authHeader() })
//   .then(response => {
//     const retreived=response.data.rows;
//     console.log('listCodes response=', response);
//     // 200 - OK - list "total": 0, "rows": []
    
//     console.log('retreived',retreived)
//     return Promise.resolve(retreived);
//   })
//   .catch(error => {
//     console.error('listCodes',error);
//     if (error.response && error.response.status === 400) {
//       return Promise.reject(error.response.data.message);
//     }
//     // 400 - Code is not valid
//     // 403 - Forbidden
//     // 404 -  Not found
//     return Promise.reject(error.response.status);
//   });
  
// }

async setName(displayName) {
  console.log('setName = ', displayName);
  return await axios.post(API_URL + 'profile/name', { 
    'name': displayName
  }, { headers: authHeader() })
  .then(response => {
    console.log('DONE Response: ',response);
    console.log('DONE Response Data: ',response.data);
    return Promise.resolve(response.data);
  })
  .catch(error => {
    console.log('ERROR Response:', error.response);
    console.log('ERROR Response Status:', error.response.status);
    console.log('ERROR Response Data.Error:', error.response.data.error);
    console.log('ERROR Response Data.Message:', error.response.data.message);
    console.log('ERROR Response Data.Status Code:', error.response.data.statusCode);
    return Promise.reject(error.response.status);
  });
}

async activateCode(actCode) {
  console.log('activateCode');
  return await axios.post(API_URL + 'profile/code', { 
    'code': actCode
  }, { headers: authHeader() })
  .then(response => {
    console.log('DONE Response: ',response);
    console.log('DONE Response Data: ',response.data);
    return Promise.resolve(response.data);
  })
  .catch(error => {
    console.log('ERROR Response:', error.response);
    console.log('ERROR Response Status:', error.response.status);
    console.log('ERROR Response Data.Error:', error.response.data.error);
    console.log('ERROR Response Data.Message:', error.response.data.message);
    console.log('ERROR Response Data.Status Code:', error.response.data.statusCode);
    return Promise.reject(error.response.status);
  });
  
}

async createWallet(paymentPassword) {
  console.log('createWallet', paymentPassword);
  return await axios.post(API_URL + 'profile/wallet', { 
    'paymentPassword': paymentPassword
  }, { headers: authHeader() })
  .then(response => {
    console.log('DONE Response: ',response);
    console.log('DONE Response Data: ',response.data);
    return Promise.resolve(response.data);
  })
  .catch(error => {
    console.log('ERROR Response:', error.response);
    console.log('ERROR Response Status:', error.response.status);
    console.log('ERROR Response Data.Error:', error.response.data.error);
    console.log('ERROR Response Data.Message:', error.response.data.message);
    console.log('ERROR Response Data.Status Code:', error.response.data.statusCode);
    return Promise.reject(error.response.status);
  });
}

async listMyTX(list_page, list_take, list_sort) {
    // POST /transactions = All my transactions
    // console.log('listTX > ', list_page, list_take, list_sort, list_key, list_user);
    console.log(' LIST MY TX : !!!! ' +API_URL + 'transactions'); //  : list_key = ', list_key, 'list_user = ',list_user
    return await axios.post(API_URL + 'transactions', { 
      'page': list_page, 
      'take': list_take, 
      'sort': list_sort, 
    //   'keyId': 8, 
    //   'keyUserId': 47, 
    }, { headers: authHeader() })
    .catch(error => {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        if (error.response && error.response.status === 400) {
            return Promise.reject(error.response.data.message);
        }
        return Promise.reject(error.response.status);
    });
  }

  async listMyKeys(list_page, list_take, list_sort, list_total) {
    console.log('listMyKeys');
    return await axios.post(API_URL + 'keys/my', { 
      'page': list_page, 
      'take': list_take, 
      'sort': list_sort, 
      'onlyTotal': list_total, 
    }, { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  async doCollect() {
    console.log('profile/collect');
    return await axios.post(API_URL + 'profile/collect', {}, { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  async listCollectTickets(list_page, list_take, list_total) {
    console.log('profile/tickets');
    return await axios.post(API_URL + 'profile/tickets', {
        'page': list_page, 
        'take': list_take, 
        'onlyTotal': list_total, 
    }, { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  async refreshCollect(ticket_id) {
    return await axios.get(API_URL + 'profile/tickets/'+ticket_id+'/sign', { headers: authHeader() });
  }

  async listCollectRefresh(ticketId) {
    console.log('profile/tickets/'+ticketId+'/sign');
    return await axios.get(API_URL + 'profile/tickets/'+ticketId+'/sign', { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  async refreshCollect(ticket_id) {
    return await axios.get(API_URL + 'profile/tickets/'+ticket_id+'/sign', { headers: authHeader() });
  }


/*


  saveProfileData(profiledata) {
    return axios.post(API_URL + 'profile', { 
      'name': profiledata.name, 
      'surName': profiledata.surName, 
      'middleName': profiledata.middleName, 
      'country': profiledata.country, 
      'nationalId': profiledata.nationalId, 
      'address': profiledata.address, 
      'city': profiledata.city, 
      'zip': Number(profiledata.zip), 
      'phone': profiledata.phone, 
    }, { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  changePassword(password0, password1, password2) {
    return axios.post(API_URL + 'profile', { 
      'password': password1, 
      'password2': password2, 
      'oldpassword': password0, 
    }, { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  // List of my codes
  getCodes() {
    return axios.get(API_URL + 'profile/code', { headers: authHeader() });
  }
  // List of ref codes
  getRefCodes() {
    return axios.get(API_URL + '/profile/initial/ref/codes', { headers: authHeader() });
  }

  */

}

export default new ProfileService();
