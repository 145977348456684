<template>
    
</template>
    <!--
    {{ USERDATA }}
    <div style="font-size: 5px; font-weight: bold;" @click="copyToClipboard(currentUser.accessToken)">{{ currentUser.accessToken }}</div>
    -->

<script>
import ProfileService from "@/services/profile.service";

export default {
    data() {
        return {
            USERDATA: this.$USERDATA, // global
        }
    },
    computed: {
        // loggedIn() {
        //     return this.$store.state.auth.status.loggedIn;
        // },
        //////// !!! WARNING !!! ////////
        currentUser() {
            console.log('Auth currentUser:' + this.$store.state.auth.user.accessToken);
            return this.$store.state.auth.user;
        }
        //////// !!! WARNING !!! ////////
    },
    created() {

        console.log('Auth: Component Mounted');

        // // if not logged in
        // if (!this.loggedIn) {
        //     console.log('Logged out');
        //     console.log('Redirecting to singin');
        //     // this.$router.push("/signin");
        //     // this.$router.push("/signin");
        // }

        ProfileService.getUserData().then(
            (response) => {
                this.userdata = response.data;
                console.log('userdata',this.userdata);
                console.log('USERSATTUS',this.userdata.status);
                this.saveUserData(this.userdata);
                if(this.userdata.status===0) {
                    console.log('Auth: Status 0 = ',this.$route.path);
                    // if(this.$route.path!=="/hash") /// WARNING
                    this.$router.push({ name: 'signup1'})
                }
                if(this.userdata.status===2) {
                    console.log('Auth: Status 2 = ',this.$route.path);
                    this.$router.push({ name: 'signup2'})
                }
                if(this.userdata.status===1) {
                    console.log('Auth: Status 0 = ',this.$route.path);
                    if(this.$route.path!=="/hash") /// WARNING
                    this.$router.push({ name: 'signup2'})
                }
                this.$emit('gotUSERDATA');
                
            },
            (error) => {
                console.log('error',error);
                this.$store.dispatch("auth/refresh").then(
                (response) => {
                    console.log('Token refreshed, retrying receive data...');
                    ProfileService.getUserData().then(
                        (response) => {
                            this.userdata = response.data;
                            this.saveUserData(this.userdata);
                            this.$emit('gotUSERDATA');
                            this.$router.push('/home') ////// WARNING ///// not a good solution, however
                        });
                    },
                    (error) => {
                        console.log('Refresh token failed, logging out...');
                        this.$router.push('/expired')
                    }
                );    
            }
        ); 

  },
  methods: {

    saveUserData(userdata) {
        console.log('saveUserData',userdata);
        this.USERDATA.id = userdata.id;
        this.USERDATA.mail = userdata.email;
        this.USERDATA.status = userdata.status;
        if(userdata.keys.length>0) this.USERDATA.hasKey = true; else this.USERDATA.hasKey = false;
        this.USERDATA.keys = userdata.keys;
        this.USERDATA.myAgents = this.USERDATA.keys.filter(item => item.status === 1);
        console.log('myAgents',this.USERDATA.myAgents);
        this.USERDATA.emptyKeys = this.USERDATA.keys.filter(item => item.status === 0);
        this.USERDATA.NAME = this.formatName(userdata.displayName, userdata.id);
        this.USERDATA.name = userdata.displayName;
        this.USERDATA.raw = userdata;

    }

  }
}
</script>