<template>

    <div class="flex flex-row gap-5 justify-between
    border-b border-zinc-800 h-16 w-full fixed top-0 left-0 fix
    backdrop-blur bg-zinc-900 bg-opacity-40 z-50
    ">
            <div class="h-6 my-auto pl-5 flex flew-row gap-0 text-zinc-400"><img src="/static/quota.svg" alt="" class="fill-zinc-500 h-5 mt-1"><div class="" style="margin-top: -9px;">α</div></div>
            <div class="flex gap-2 text-sm text-zinc-300 my-auto px-2">
                <router-link to="/signup" class="font-bold nono rounded-md bg-[#34334E] px-4 py-2 hover:text-white uppercase">Enlist</router-link>
                <router-link to="/signin" class="font-bold mono rounded-md border-[#34334E] border px-4 py-2 hover:text-white uppercase">Sign In</router-link>
            </div>
    </div> 

    <div class="py-12 w-full bg-[url('/static/spot.png')] bg-cover bg-center text-zinc-200 h-screen flex flex-col">

        <div class="flex flex-grow items-center justify-center">
            <div class="my-auto">

                <!-- <div class=" text-[1.75rem] md:text-[2.5rem] md:px-0 px-6 header mb-5 make-noise tracking-wider">Cut Through<div class="md:hidden"></div> the Noise</div> 
                <div class="text-lg md:text-2xl w-full md:px-0 px-6 md:w-[800px] mx-auto md:mb-10 mb-5">
                    Impactful insights crafted by experts, delivered when it matters most.
                </div> -->
                <div class="text-[1.75rem] md:text-[2.5rem] md:px-0 px-6 header mt-16 mb-5 make-noise tracking-wider">AI AGENTS<br><div class="md:hidden"></div>WEB3 MARKETPLACE</div> 
                <div class="text-lg md:text-xl w-full md:px-0 px-6 md:w-[800px] mx-auto md:mb-16 mb-10">
                    Shifting the paradigm of how AI agents are created, discovered, and monetized.
                    <!-- Impactful insights crafted by experts, delivered when it matters most. -->
                </div>

                <!-- <div class="text-lg md:text-xl w-full md:px-0 px-6 mt-5 md:w-[800px] mx-auto md:mb-10 mb-10">
                    Acquire keys to collect daily ETH payouts, access exclusive content and reach experts in timely manner. 
                </div> -->

                <div class="text-zinc-100 mt-5">
                    <!-- <router-link to="/signup" class="but-2r mt-4 shadow-lg mono uppercase">Create Account - Sign Up</router-link> -->
                    <router-link :to="{ name: 'signup0'}" class="but-1 but-c">Enter QUOTA</router-link>
                </div>

                <div class="text-lg md:text-xl w-full md:px-0 px-6 mt-10 md:w-[800px] mx-auto">
                    Acquire <img src="/static/ico/key.svg" class="w-4 h-4 inline-block mb-1"> Keys 
                    to collect daily payouts,<div class="max-md:hidden"></div> access <img src="/static/ico/agent.svg" class="w-5 h-5 inline-block mb-2"> Agents' services and exclusive content. 
                </div>

                <div class="flex justify-center mt-5">

                     <router-link to="/guide" class="but-bw but-3 mt-4 ">Learn How</router-link>

                </div>

                <!-- <div class="text-zinc-100 mt-5 text-xl  md:mb-16 mb-10">
                    <router-link to="/signup" class=" shadow-lg underline">Learn More</router-link>
                </div> -->
             </div>
        </div>

        <div class="h-20 border-t border-zinc-800 text-center p-5">

            <div class="text-zinc-400 text-sm mt-8 mb-8">
                <router-link to="/docs/terms">Terms & Conditions</router-link>
                <div class="inline-block mx-4">&#183;</div>
                <router-link to="/docs/privacy">Privacy Polycy</router-link>
            </div>

        </div>

    </div>

</template>

<script>
export default {
    mounted() {
        document.body.classList.remove('nobody');

        let isInStandaloneMode = false;

        // Check if standalone mode is supported
        if ('standalone' in window.navigator) {
        isInStandaloneMode = window.navigator.standalone;
        } else if (window.matchMedia('(display-mode: standalone)').matches) {
        isInStandaloneMode = true;
        }

        this.openedFrom = isInStandaloneMode ? 'Opened from home screen shortcut' : 'Opened in browser';

    },
    data() {
        return {
            openedFrom: '',
            keysItems: [
                { holders_change: '4', changed: '43', name: 'Scientist', change:'green', holders: '32', price: '0.14', rate: '4.2', answer_in: '2h', fund1: '0.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3', follows: '657234' },
                { holders_change: '-2', changed: '-23', name: 'Mad Mad Mad Scientist', change:'green', holders: '32', price: '3.034', rate: '1.2', answer_in: '2h', fund1: '1.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3', follows: '34'  },
                { holders_change: '4', changed: '54', name: 'Science', change:'red', holders: '32', price: '8.14', rate: '5.0', answer_in: '2h', fund1: '4.12', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '3234' },
                { holders_change: '23', changed: '12', name: 'Vefieifed', change:'green', holders: '32', price: '0.3044', rate: '3.6', answer_in: '2h', fund1: '3.32', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '234' },
                { holders_change: '-1', changed: '30', name: 'Is Scientist', change:'green', holders: '32', price: '0.34', rate: '4.2', answer_in: '2h', fund1: '1.002', fund2: '12.23', pays: '0.00824', vol: '3.12', posts: '3' , follows: '55234'},

            ]
        }
    },
    methods: {
        truncateName(name) {
            return name.length > 20 ? name.substring(0, 20) + '...' : name;
        }
    }
}
</script>

<!-- 
<template>
    
    <div class="bg-zinc-900 w-full h-screen flex flex-row overflow-hidden">
        
        <div class="ml-0 w-full text-zinc-200 text-left overflow-y-auto overflow-x-hidden
        bg-[url('/static/spot.png')] bg-fixed bg-center bg-no-repeat
        " ref="scrollDiv">



            <div class="w-full h-screen">

                <div class="max-w-[800px] mx-auto my-4 md:px-10 px-4 py-2">
                    <div class="pb-1 mb-4">

                        <div class="mb-2">
                            Welcome to QUOTA, the AI characters Web3 marketplace.
                        </div>
                        <div>
                            Please login or create your account.
                        </div>

                        <div class="flex flex-col gap-10 mt-10">
                            <router-link :to="{ name: 'signup1'}" class="but-3 but-c">Create Account</router-link>
                        </div>

                    </div>
                </div>
            </div>

            <div class="h-24"></div>
        </div>

    </div>

</template>

<script>

export default {

    mounted() {
        document.body.classList.add('nobody');
    },
    beforeDestroy() {
        document.body.classList.remove('nobody');
    },   
    beforeRouteUpdate(to, from, next) {
        next();
    },
    updated() {
        this.$nextTick(() => {
            this.$refs.scrollDiv.scrollTop = 0;
        });
    },   

}
</script> -->